import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Zoe = () => (
  <Layout>
    <SEO title="Zoe" />
    <h1>Hi I'm Zoe</h1>
    <p>I'm super cute and innocent and love to torment my big bro. Adopted on April 17, 2021</p>
    <div>
      <img src="https://res.cloudinary.com/angular/image/upload/c_scale,w_600/v1615769613/welovetonky/zoe.jpg" alt="Zoe" />
    </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Zoe
